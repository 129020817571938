.talk-bubble {
	margin: 0.5rem;
  display: inline-block;
  position: relative;
	width: 92%;
	height: auto;
	background-color: lightblue;
}
.round{
  border-radius: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;

}
.talktext{
  padding: 1em;
	text-align: left;
  line-height: 1.5em;
}
