.dashboard{
    display: flex;
    justify-content: space-evenly;
    }

.card1{
    text-align: center;
    background-color: bisque;
    display: flex;
    flex-direction: column;
}

.card2{
    text-align: center;
    background-color: #E1F8DC;
    display: flex;
    flex-direction: column;
}

.card3{
    text-align: center;
    background-color: #ACDDDE;
    display: flex;
    flex-direction: column;
}

.card4{
    text-align: center;
    background-color: #FFE9BF;
    display: flex;
    flex-direction: column;
}
.cardh1{
    padding-top: 2rem;
}

.bottomSentence{
    padding-top: 1rem;
}

.card1, .card2, .card3, .card4{
    font-size: .8rem;
    height: 15rem;
}

@media screen and (max-width:768px){
    .card1, .card2, .card3, .card4{
        font-size: 1rem;
    }
}

@media screen and (max-width:376px){
    .dashboard{
        flex-direction: column;
    }
    .card1, .card2, .card3, .card4{
        font-size: .7rem;
        height: 12rem;
    }
}