@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');

body {
  margin-top: 20px;
  padding-top: 55px;
  padding-right: 10px;
  padding-left: 10px;
}

h2 {
  font-family: 'Montserrat', sans-serif;
}
