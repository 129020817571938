@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap);
body {
  margin-top: 20px;
  padding-top: 55px;
  padding-right: 10px;
  padding-left: 10px;
}

h2 {
  font-family: 'Montserrat', sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.Login_Wrapper__3kF6A {
  display: flex;
  flex-direction: row;
}

.Login_Left__9xb0r {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.Login_Right__39MLf {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

#Login_Img_Pane__2d7J4 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.Login_Login__3Jtg4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 1200px) {
  .Login_Left__9xb0r {
    flex: 2 1;
  }

  .Login_Right__39MLf {
    flex: 6 1;
  }
}

@media only screen and (max-width: 1024px) {
  .Login_Left__9xb0r {
    display: none;
  }
}

.dashboard{
    display: flex;
    justify-content: space-evenly;
    }

.card1{
    text-align: center;
    background-color: bisque;
    display: flex;
    flex-direction: column;
}

.card2{
    text-align: center;
    background-color: #E1F8DC;
    display: flex;
    flex-direction: column;
}

.card3{
    text-align: center;
    background-color: #ACDDDE;
    display: flex;
    flex-direction: column;
}

.card4{
    text-align: center;
    background-color: #FFE9BF;
    display: flex;
    flex-direction: column;
}
.cardh1{
    padding-top: 2rem;
}

.bottomSentence{
    padding-top: 1rem;
}

.card1, .card2, .card3, .card4{
    font-size: .8rem;
    height: 15rem;
}

@media screen and (max-width:768px){
    .card1, .card2, .card3, .card4{
        font-size: 1rem;
    }
}

@media screen and (max-width:376px){
    .dashboard{
        flex-direction: column;
    }
    .card1, .card2, .card3, .card4{
        font-size: .7rem;
        height: 12rem;
    }
}
p {
    font-family: 'Poppins', Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.125rem;
    color: rgb(26, 27, 27);
    padding:.5rem 2rem;
    padding: 8px;
    margin-left: 3%;
}


p {
    font-family: 'Poppins', Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.125rem;
    color: rgb(26, 27, 27);
    padding:.5rem 2rem;
    padding: 8px;
    margin-left: 3%;
}


p {
    font-family: 'Poppins', Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.125rem;
    color: rgb(26, 27, 27);
    padding:.5rem 2rem;
    padding: 8px;
    margin-left: 3%;
}


p {
    font-family: 'Poppins', Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.125rem;
    color: rgb(26, 27, 27);
    padding:.5rem 2rem;
    padding: 8px;
    margin-left: 3%;
}


p {
    font-family: 'Poppins', Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.125rem;
    color: rgb(26, 27, 27);
    padding:.5rem 2rem;
    padding: 8px;
    margin-left: 3%;
}


p {
    font-family: 'Poppins', Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.125rem;
    color: rgb(26, 27, 27);
    padding:.5rem 2rem;
    padding: 8px;
    margin-left: 3%;
}


p {
    font-family: 'Poppins', Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.125rem;
    color: rgb(26, 27, 27);
    padding:.5rem 2rem;
    padding: 8px;
    margin-left: 3%;
}


p {
    font-family: 'Poppins', Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.125rem;
    color: rgb(26, 27, 27);
    padding:.5rem 2rem;
    padding: 8px;
    margin-left: 3%;
}
.talk-bubble {
	margin: 0.5rem;
  display: inline-block;
  position: relative;
	width: 92%;
	height: auto;
	background-color: lightblue;
}
.round{
  border-radius: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;

}
.talktext{
  padding: 1em;
	text-align: left;
  line-height: 1.5em;
}

